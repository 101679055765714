import React from "react"
import Layout from "../components/layout"

import Sezione from "../components/sezione"
import SEO from "../components/seo"
import CardPagina from "../components/cardPagina"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import BottoneBianco from "../components/bottoneBianco"
import { Link } from "gatsby"

import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Tab,
  ListGroup,
  Tabs,
} from "react-bootstrap"

const Cogenerazione = () => (
  <Layout isPagina={true}>
    <SEO
      title="Micro Cogenerazione per produzione energia elettrica e acqua calda sanitaria Lazio: Roma, Pomezia, Aprilia, Ardea, Anzio, Nettuno, Latina"
      description="Realizzazione Impianti a cogenerazione nel Lazio. Risparmio energetico e alto rendimento"
      location="cogenerazione/"
    />
    <BanneronePage title="Cogenerazione" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Risparmio energetico con impianti a cogenerazione nella regione Lazio. Alto rendimento per i tuoi impianti a Roma, Nettuno, Anzio, Pomezia, Latina, Aprilia" />
      <CardPagina>
        <p className="text-center">
          <b>
            {" "}
            Soluzioni Energetiche Aziendali e Residenziali: <br />{" "}
            MICRO-COGENERAZIONE AD ALTO RENDIMENTO
          </b>
        </p>
        <Row>
          <Col xs={12}>
            <img
              className="rounded mt-1 mx-auto img-fluid "
              src="../cogen/cogenerazione.jpg"
              alt="Produzione contemporanea di acqua calda industriale ed energia elettrica"
            />
          </Col>
        </Row>

        <p className="text-center">
          <b>
            In alternativa o ad integrazione del fotovoltaico:
            <br />
            tempi di ammortamento di 2-3 anni con la detrazione fiscale (IRES o
            IRPEF) del 65%
            <br />o con gli incentivi del GSE - Certificati Bianchi -
          </b>
        </p>
        <p className="gridParity">
          Produzione di Acqua Calda Sanitaria o Tecnica, acqua per processi industriali +
          Riscaldamento + Energia Elettrica
        </p>
      </CardPagina>
      <Sezione title="Opportunità per Aziende e Privati">
        <CardPagina variante="bordoLeft bordoRight">
          <p>
            <b>
              Perchè installare un impianto a MICRO-COGENERAZIONE di NSE srl?
            </b>
          </p>
          <p>
            <u>
              <b>I risparmi sulla bolletta sono considerevoli.</b>
            </u>
            <br />
            Un impianto a micro-cogenerazione di NSE srl è in grado di{" "}
            <u>
              produrre direttamente dove e quando serve sia energia elettrica
              che termica
            </u>
            . Cogenerare l’energia ove essa è necessaria, oltre ad essere una
            scelta eco-sostenibile, è soprattutto una scelta economicamente
            vantaggiosa:{" "}
            <u>
              il costo del kWh elettrico risulta infatti inferiore a quello
              acquistato da rete
            </u>{" "}
            . Diversamente da altri interventi di efficienza energetica, un
            impianto a cogenerazione, grazie alla durata della macchina, alle
            detrazioni fiscali del 65%/incentivi del GSE ed ai costi di
            manutenzione contenuti, risulta essere un vero e proprio
            investimento finanziario con tempi di rientro molto brevi
            (mediamente intorno ai 2-3 anni). Dotare il proprio immobile o la
            propria attività commerciale di un impianto a cogenerazione
            significa inoltre aumentarne considerevolmente il valore.
          </p>
          <p>
            <u>
              <b>Ampia gamma di potenze disponibili</b>
            </u>{" "}
            <br />I 5 modelli offerti da NSE srl con potenze elettriche da 10,
            20, 25, 35 e 45 kW elettrici (e rispettivamente 22, 47.5, 62, 77, 86
            kW termici), sono studiati per soddisfare le diverse necessità
            energetiche sia in ambito residenziale, sia aziendale anche
            evntualmente con l'abbinamento di generatori multipli.
          </p>
          <p>
            <u>
              <b>Eleveta versatilità</b>
            </u>{" "}
            <br />
            Un impianto a cogenerazione di NSE srl, ancora meglio di altri
            sistemi di generazione che promuovono l’efficientamento energetico
            (come il solare fotovoltaico e termico), è una macchina versatile
            sia nella generazione dell’energia (del tutto programmabile in
            quanto non vincolata alle condizioni di irraggiamento), sia
            nell’occupazione degli spazi (non necessita di ampi spazi esterni né
            di esposizioni ottimali).
            <br />
            Possibili settori d'impiego:
            <ul>
              <li>Ricettivo: alberghi, villaggi turistici, campeggi</li>
              <li>Benessere: piscine, SPA, stabilimenti termali</li>
              <li>Ristorazione: ristoranti, mense</li>
              <li>Helthcare: cliniche, case di riposo</li>
              <li>Pubblico: scuole, caserme, uffici</li>
              <li>Residenziale: condomini, residence, ville</li>
              <li>Agroalimentare: caseifici, pastifici, aziende agricole</li>
              <li>Distribuzione: supermercati, centri commerciali</li>
            </ul>
          </p>
          <p>
            <u>
              <b>Assistenza a 360°</b>
            </u>{" "}
            <br />I 5 modelli offerti da NSE srl con potenze elettriche da 10,
            20, 25, 35 e 45 kW elettrici (e rispettivamente 22, 47.5, 62, 77, 86
            kW termici), sono studiati per soddisfare le diverse necessità
            energetiche sia in ambito residenziale, sia aziendale anche
            evntualmente con l'abbinamento di generatori multipli.
          </p>
          <p>
            <u>
              <b>Rispetto dell'ambiente</b>
            </u>{" "}
            <br />
            L’utilizzo di un impianto a cogenerazione dimezza le emissioni
            nocive (CO2 e polveri sottili). Per esempio: 4.000 ore di lavoro del
            modello da 20 kW evitano l’immissione in atmosfera di 26 tonnellate
            di anidride carbonica.
          </p>
          <p>
            <u>
              <b>Tecnologia italiana</b>
            </u>{" "}
            <br />
            L’utilizzo di un impianto a cogenerazione dimezza le emissioni
            nocive (CO2 e polveri sottili). Per esempio: 4.000 ore di lavoro del
            modello da 20 kW evitano l’immissione in atmosfera di 26 tonnellate
            di anidride carbonica.
          </p>
        </CardPagina>
      </Sezione>
      <CardPagina variante="white bordoTop mt-5">
        <Row>
          <Col xs={12} className="mt-3">
            <p className="titoloGrande text-center gridParity">
              CONSULENZA, PROGETTAZIONE, FINANZIAMENTO, REALIZZAZIONE E
              MANUTENZIONE:
              <br />
              Impianti a micro-cogenerazione aziendali e residenziali Regione
              Lazio: <br />
              <strong>prezzo medio € 2.000 al kWp.</strong>
            </p>
          </Col>
          <Col xs={12}>
            <BottoneBianco
              className="mt-3 mx-auto text-center variantePage"
              title="Contattaci"
              link="contatti"
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid"
              src="../cogen/Impianti-a-cogenerazione-lazio.jpg"
              alt="impianti a cogenerazione Lazio"
            />
          </Col>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid"
              src="../cogen/Impianti-a-cogenerazione-roma.jpg"
              alt="impianti fotovoltaici Roma"
            />
          </Col>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid "
              src="../cogen/produzione-acqua-calda-e-riscaldamento.jpg"
              alt="Produzione acqua Calda e Riscaldamento"
            />
          </Col>
        </Row>
      </CardPagina>
    </Container>
  </Layout>
)

export default Cogenerazione
